import React from 'react'
import Header from './components/Header'
import Posts from './components/Posts'
import './index.css'

import LogoPng from "./assets/img/logo.png"



function App() {
  return (
    <div className="container">
      
      
      <div className="centered">
      <img class="logo" src={LogoPng}/>
      </div>
    </div>
  )
}
export default App